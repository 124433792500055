import React, { useRef, useCallback } from "react";
import ReactRating from "react-rating";
import { create, define } from "../web-component";

const LevelRating = ({ initialValue, elementName, readOnly = false }) => {
  const inputRef = useRef(null);

  const handleChange = useCallback(
    (value) => {
      if (inputRef.current) {
        inputRef.current.value = value;
      }
    },
    [inputRef]
  );

  return (
    <>
      <ReactRating
        initialRating={initialValue}
        onClick={handleChange}
        emptySymbol={<i className="material-icons">star_border</i>}
        fullSymbol={<i className="material-icons">star</i>}
        readonly={readOnly}
      />
      {!readOnly && (
        <input
          ref={inputRef}
          className={"hidden"}
          name={elementName}
          defaultValue={initialValue}
        />
      )}
    </>
  );
};

export default LevelRating;

define("level-rating", create(LevelRating));
