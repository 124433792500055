import React from "react";
import linkifyHtml from "linkifyjs/html";
import { create, define } from "../web-component";

const LinkText = ({ children }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: linkifyHtml(children) }}></div>
  );
};

export default LinkText;

define("link-text", create(LinkText));
