import React, { useCallback, useRef, useState } from "react";
import IconMenu from "./IconMenu";
import { create, define } from "../web-component";

const SearchBar = ({ label }) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState("");
  const handleOpen = useCallback((open) => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  const stopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const handleClear = useCallback(
    (e) => {
      stopPropagation(e);
      setValue("");
      inputRef.current.focus();
    },
    [setValue]
  );

  return (
    <IconMenu icon="search" label={label} onOpen={handleOpen}>
      <form action="/search" method="GET">
        <div className="flex flex-row items-center">
          <input
            ref={inputRef}
            className="outline-none"
            placeholder={label}
            name="search"
            value={value}
            onClick={stopPropagation}
            onChange={handleChange}
          />
          <i className="material-icons cursor-pointer" onClick={handleClear}>
            close
          </i>
        </div>
      </form>
    </IconMenu>
  );
};

define("search-bar", create(SearchBar));
