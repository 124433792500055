import React, { useState, useRef, useCallback, useEffect } from "react";
import cx from "classnames";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import useIsMounted from "ismounted";
import { create, define } from "../web-component";
import { useDebounce } from "../hooks";

const ImageCropper = ({
  containerClass = "",
  width,
  height,
  defaultImage,
  autoCropArea,
  onCrop,
  element,
  src,
  aspectRatio,
  jpg,
  confirmText,
  cancelText,
  required,
}) => {
  const isMounted = useIsMounted();
  const [preview, setPreview] = useState(defaultImage);
  const [canvas, setCanvas] = useState(null);
  const cropperRef = useRef(null);
  const debounceCanvas = useDebounce(canvas, 300);
  const [tempCanvas, setTempCanvas] = useState(null);

  useEffect(() => {
    if (debounceCanvas) {
      const dataURL = debounceCanvas.toDataURL(jpg ? "image/jpeg" : undefined);
      if (onCrop) {
        onCrop(dataURL);
      }
      if (element) {
        document.querySelector(element).value = dataURL;
      }
    }
  }, [debounceCanvas]);

  const handleCrop = useCallback(() => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (isMounted.current) {
      const temp = cropper?.getCroppedCanvas({
        width,
        height,
        fillColor: "#fff",
      });
      // FIXME: a hack to set value for the first crop
      if (!window.IMAGE_CROPPER_FIRST_CROP) {
        window.IMAGE_CROPPER_FIRST_CROP = {};
      }
      if (!window.IMAGE_CROPPER_FIRST_CROP[element]) {
        setCanvas(temp);
        window.IMAGE_CROPPER_FIRST_CROP[element] = true;
      }
      setTempCanvas(temp);
    }
  }, [cropperRef]);

  const handleUpload = useCallback(
    (e) => {
      const input = e.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          setPreview(e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    [setPreview]
  );
  const handleSetCropperZoom = useCallback(
    (e, v) => {
      e.stopPropagation();
      e.preventDefault();
      const imageElement = cropperRef?.current;
      const cropper = imageElement?.cropper;
      cropper?.zoom(v);
    },
    [cropperRef]
  );

  const handleConfirm = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      setCanvas(tempCanvas);
      setPreview(tempCanvas.toDataURL(jpg ? "image/jpeg" : undefined));
    },
    [setCanvas, tempCanvas]
  );

  const handleCancel = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setPreview("");
    document.querySelector(element).value = "N/A";
  }, []);

  return (
    <div>
      <div className={containerClass}>
        {preview ? (
          <Cropper
            src={preview}
            className={cx("max-w-full", containerClass)}
            style={{ height, width }}
            // Cropper.js options
            initialAspectRatio={aspectRatio || width / height}
            aspectRatio={aspectRatio || width / height}
            autoCropArea={autoCropArea}
            guides={false}
            crop={handleCrop}
            ref={cropperRef}
            dragMode={"crop"}
            zoomOnWheel={false}
            // FIXME: https://github.com/fengyuanchen/cropperjs/issues/149
            checkOrientation={false}
          />
        ) : src ? (
          <img
            style={{ maxWidth: width, maxHeight: height }}
            className=""
            src={src || `/images/upload-placeholder.png`}
            alt="default"
          />
        ) : (
          <div
            style={{ maxWidth: width, maxHeight: height }}
            className="bg-gray-light"
          >
            <img
              style={{ maxWidth: width, maxHeight: height }}
              className="object-contain w-full h-full"
              src={src || `/images/upload-placeholder.png`}
              alt="default"
            />
          </div>
        )}
      </div>
      <div className="w-full mt-2 flex flex-row items-center">
        <button
          className="mr-2 bg-primary-ocean text-white focus:outline-none flex justify-center items-center p-1 rounded"
          onClick={(e) => handleSetCropperZoom(e, 0.1)}
        >
          <i className="material-icons">zoom_in</i>
        </button>
        <button
          className="bg-primary-ocean text-white focus:outline-none flex justify-center items-center p-1 rounded "
          onClick={(e) => handleSetCropperZoom(e, -0.1)}
        >
          <i className="material-icons">zoom_out</i>
        </button>
      </div>
      <div className="w-full mt-2 flex flex-col sm:flex-row items-center">
        <input
          className="mr-0 mb-2 sm:mr-2 sm:mb-0"
          type="file"
          onChange={handleUpload}
          required={required}
        />
        <button className="bio-button" onClick={handleConfirm}>
          {confirmText}
        </button>
        <button className="ml-2 bio-button" onClick={handleCancel}>
          {cancelText}
        </button>
      </div>
    </div>
  );
};

define("image-cropper", create(ImageCropper));
