import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { createUseStyles } from "react-jss";
import IconMenu from "./IconMenu";
import { create, define } from "../web-component";

const useStyles = createUseStyles({
  item: {
    color: "var(--gray-light)",
  },
  active: {
    color: "black",
  },
});

const FontSizeMenu = ({ items, label, defaultSize }) => {
  const [currentSize, setCurrentSize] = useState(defaultSize);

  const changeFontSize = useCallback((size) => {
    if (size === "large") {
      document.documentElement.classList.remove("font-size-normal");
      document.documentElement.classList.add("font-size-large");
    } else {
      document.documentElement.classList.remove("font-size-large");
      document.documentElement.classList.add("font-size-normal");
    }
    document.cookie = `font_size=${size}`;
  }, []);

  useEffect(() => {
    changeFontSize(currentSize);
  }, [currentSize]);

  const classes = useStyles();

  return (
    <IconMenu icon="font_download" label={label}>
      {items.map(({ label, value }) => (
        <div
          key={label}
          className={cx("mx-4 cursor-pointer", classes.item, {
            [classes.active]: currentSize === value,
          })}
          onClick={() => setCurrentSize(value)}
        >
          <span className="whitespace-no-wrap">{label}</span>
        </div>
      ))}
    </IconMenu>
  );
};

define("font-size-menu", create(FontSizeMenu));
