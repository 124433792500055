import React from "react";
import { Line, Bar, Pie } from "@reactchartjs/react-chart.js";
import { create, define } from "../web-component";

const mapMembersToDateGroup = (members) => {
  const list = [...members].sort(
    (a, b) => new Date(a.created_at) - new Date(b.created_at)
  );
  return list.reduce((groups, item) => {
    const date = item.created_at.split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(item);
    return groups;
  }, {});
};

const processMembersForLine = (members) => {
  const groups = mapMembersToDateGroup(members);
  const labels = Object.keys(groups);
  labels.sort((a, b) => new Date(a) - new Date(b));
  const cumulativeSum = ((sum) => (value) => (sum += value))(0);
  return {
    labels,
    datasets: [
      {
        label: "# of Members",
        data: labels.map((label) => groups[label].length).map(cumulativeSum),
      },
    ],
  };
};

const processMembersForBar = (members) => {
  const groups = mapMembersToDateGroup(members);
  const labels = Object.keys(groups);
  labels.sort((a, b) => new Date(a) - new Date(b));
  return {
    labels,
    datasets: [
      {
        label: "# of Members joined",
        data: labels.map((label) => groups[label].length),
      },
    ],
  };
};

const processMembersForPie = (members, texts) => {
  const groups = members.reduce((a, b) => {
    if (!a[b.type]) {
      a[b.type] = [];
    }
    a[b.type].push(b);
    return a;
  }, {});
  const labels = Object.keys(groups);
  return {
    labels: labels.map((label) => texts[label]),
    datasets: [
      {
        label: "% of Members type",
        data: labels.map((label) => groups[label].length),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
      },
    ],
  },
};

const MemberChart = ({ members, texts }) => {
  return (
    <div className="w-full h-full grid grid-cols-2 gap-4">
      <div className="border">
        <Line data={processMembersForLine(members)} options={options} />
      </div>
      <div className="border">
        <Bar data={processMembersForBar(members)} options={options} />
      </div>
      <div className="border">
        <Pie data={processMembersForPie(members, texts)} />
      </div>
    </div>
  );
};

define("member-chart", create(MemberChart));
