import React from "react";
import { create, define } from "../web-component";
import Select from "react-select/creatable";

const SkillSetFilter = ({
  items,
  showSearchButton,
  isMultiple,
  defaultType,
  defaultOptionText,
  defaultOther,
  searchText,
  createLabel,
}) => {
  const lists = Object.keys(items);
  lists.sort((a, b) => (a > b ? 1 : -1));

  const defaultValue = {};
  Object.keys(defaultType).forEach((k) => {
    const value = defaultType[k];
    defaultValue[k] = Array.isArray(value)
      ? value.map((el) => el.toString())
      : value;
  });

  return (
    <div className="w-full flex flex-row flex-wrap md:flex-no-wrap justify-center items-center">
      {lists.map((el, index) => (
        <Select
          key={el}
          className="w-full p-1 flex-none sm:w-1/2 md:flex-1 md:w-auto md:min-w-40 lg:min-w-48"
          styles={{
            control: (provided) => ({
              ...provided,
              backgroundColor: "var(--color-gray-thin)",
              minHeight: "43px",
            }),
          }}
          name={el}
          options={items[el].content}
          defaultValue={
            defaultValue[el]
              ? [
                  ...items[el].content.filter((e) => {
                    const value = Array.isArray(defaultValue[el])
                      ? defaultValue[el]
                      : [defaultValue[el]];
                    return value.indexOf(e.value.toString()) !== -1;
                  }),
                  ...((defaultOther && defaultOther[el]) || []),
                ]
              : null
          }
          placeholder={defaultOptionText[index]}
          isMulti={isMultiple}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          formatCreateLabel={(input) => `${createLabel} ${input}`}
        />
      ))}
      {showSearchButton && (
        <button
          type="submit"
          className="mx-2 bg-gray-bold px-8 py-2 text-white min-w-24 w-full md:w-auto rounded-full md:rounded-none mt-2 md:mt-0"
        >
          {searchText}
        </button>
      )}
    </div>
  );
};

define("skill-set-filter", create(SkillSetFilter));
