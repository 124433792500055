import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { create, define } from "../web-component";

const Portal = ({ children }) => ReactDOM.createPortal(children, document.body);

const ImageEnlarger = ({ class: className, src, alt }) => {
  const [enlarged, setEnlarge] = useState(false);

  useEffect(() => {}, [enlarged]);

  return (
    <>
      <img
        className={className}
        src={src}
        alt={alt}
        onClick={() => setEnlarge(true)}
      />
      {enlarged && (
        <Portal>
          <div
            className={
              "fixed flex flex-column justify-center items-center inset-0 h-full bg-black bg-opacity-50 z-10"
            }
          >
            <img
              src={src}
              alt={alt}
              className={"cursor-pointer"}
              onClick={(e) => {
                e.stopPropagation();
                window.open(src, "_blank");
              }}
            />
            <div
              style={{ lineHeight: 0 }}
              className={
                "absolute top-2 right-2 p-4 rounded-full bg-white cursor-pointer"
              }
              onClick={() => setEnlarge(false)}
            >
              <i className={"material-icons"}>clear</i>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default ImageEnlarger;
define("image-enlarger", create(ImageEnlarger));
