import React, { useCallback, useState } from "react";
import cx from "classnames";
import LinkText from "./LinkText";
import { create, define } from "../web-component";

const ExpandableItem = ({
  data,
  locale,
  institutionText,
  positionText,
  addressText,
  expertiseText,
  csrfToken,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  const {
    id,
    title,
    institution,
    position,
    address,
    email,
    phone,
    website,
    introduction,
    liked,
    likes,
    premium,
  } = data;

  return (
    <div className="w-full h-full">
      <div
        className={cx(
          "w-full flex flex-row px-4 py-4 border-b border-gray-bold cursor-pointer",
          {
            "bg-primary-ocean text-white shadow": expanded,
          }
        )}
        onClick={handleClick}
      >
        <div className="flex-1 w-0 flex flex-col">
          <div className="flex flex-row flex-wrap mb-2">
            {expertiseText.map((e) => (
              <span
                key={e}
                className={cx(
                  "border text-sm mr-2 mb-1 px-4 py-1 rounded-full",
                  {
                    "border-gray-bold": !expanded,
                    "text-gray-bold": !expanded,
                  },
                  { "border-white": expanded, "text-white": expanded }
                )}
              >
                {e}
              </span>
            ))}
          </div>
          <div className="flex flex-row items-center">
            {premium && (
              <div className="w-8 h-8">
                <img
                  className="w-full h-full"
                  src="/images/icons/premium.svg"
                  alt="premium"
                />
              </div>
            )}
            <div className="truncate">
              <a
                href={website}
                target="_blank"
                rel="noreferrer"
                className="whitespace-no-wrap"
                onClick={(e) => e.stopPropagation()}
              >
                {title}
              </a>
            </div>
          </div>
          <div
            className={cx("truncate text-sm text-gray-bold", {
              "text-gray-light": expanded,
            })}
          >
            <a
              href={`mailto:${email}`}
              className="whitespace-no-wrap"
              onClick={(e) => e.stopPropagation()}
            >
              {email}
            </a>
          </div>
          <div
            className={cx("truncate text-sm", {
              "text-gray-bold": !expanded,
              "text-gray-light": expanded,
            })}
          >
            <span>{phone}</span>
          </div>
        </div>
        <div className="flex flex-col items-end">
          {!expanded && <i className="material-icons">add</i>}
          <div className="flex-1"></div>
          <div
            className={cx("flex flex-row items-center", {
              "text-gray-bold": !expanded,
              "text-gray-light": expanded,
            })}
          >
            <form action="/collaboration/like" method="POST">
              <input type="hidden" name="_csrf" value={csrfToken} />
              <input type="hidden" name="id" value={id} />
              {liked ? (
                <>
                  <input type="hidden" name="unlike" value="true" />
                  <button
                    className="flex itesm-center justify-center"
                    data-reversed={expanded}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <i className="material-icons">favorite</i>
                  </button>
                </>
              ) : (
                <button
                  className="flex itesm-center justify-center"
                  data-reversed={expanded}
                  onClick={(e) => e.stopPropagation()}
                >
                  <i className="material-icons">favorite_border</i>
                </button>
              )}
            </form>
            <div className="ml-2 text-sm">
              <span>({likes || 0})</span>
            </div>
          </div>
        </div>
      </div>
      {expanded ? (
        <div className="px-4 py-3 md:px-10 md:py-6 border bg-white">
          <div className="grid grid-cols-3 gap-1">
            {institution && institution[locale] && (
              <>
                <span className="col-span-1">{institutionText}</span>
                <span className="col-span-2 whitespace-pre-wrap break-words">
                  {institution[locale]}
                </span>
              </>
            )}
            {position && position[locale] && (
              <>
                <span className="col-span-1">{positionText}</span>
                <span className="col-span-2 whitespace-pre-wrap break-words">
                  {position[locale]}
                </span>
              </>
            )}
            {address && address[locale] && (
              <>
                <span className="col-span-1">{addressText}</span>
                <span className="col-span-2 whitespace-pre-wrap break-words">
                  {address[locale]}
                </span>
              </>
            )}
          </div>
          <hr className="my-4" />
          {introduction && (
            <div className="whitespace-pre-wrap break-words">
              <LinkText>{introduction[locale]}</LinkText>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

define("collaboration-item", create(ExpandableItem));
