import { useCallback, useEffect } from "react";
import { create, define } from "../web-component";

const AtLeastOneRequired = ({ formId, validityText }) => {
  const queryElements = () => {
    const form = document.getElementById(formId);
    const inputs = Array.from(
      form.querySelectorAll(`[data-at-least-one-required="true"]`)
    );
    return [form, inputs];
  };
  const handleSubmit = useCallback((e) => {
    const [form, inputs] = queryElements();
    e.preventDefault();
    const required = inputs.some((element) => element.value);
    if (required) {
      form.submit();
    } else {
      inputs.forEach((input) => {
        input.setCustomValidity(validityText);
        input.reportValidity();
      });
    }
  }, []);

  const clearValidity = useCallback(() => {
    const [, inputs] = queryElements();
    inputs.forEach((input) => {
      input.setCustomValidity("");
    });
  });

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", () => {
      const [form, inputs] = queryElements();
      form.addEventListener("submit", handleSubmit);
      inputs.forEach((input) => input.addEventListener("input", clearValidity));
    });
    return () => {
      const [form, inputs] = queryElements();
      form.removeEventListener("submit", handleSubmit);
      inputs.forEach((input) =>
        input.removeEventListener("input", clearValidity)
      );
    };
  }, []);
  return null;
};

define("at-least-one-required", create(AtLeastOneRequired));
