import "flatpickr/dist/flatpickr.css";
// import "flatpickr/dist/themes/dark.css";
import React, { useEffect, useRef } from "react";
import { create, define } from "../web-component";
import useIsMounted from "ismounted";
import flatpickr from "flatpickr";
import moment from "moment";

const getDateString = (date) => (date ? moment(date).format() : "");

const DatePicker = ({
  name,
  mode,
  enableTime,
  noCalendar,
  defaultDate,
  maxDate,
  minDate,
  dateFormat = "Y-m-d",
  required,
}) => {
  const isMounted = useIsMounted();

  const containerRef = useRef(null);
  const selectRef = useRef(null);

  const isRange = mode === "range";

  useEffect(() => {
    if (isMounted.current) {
      const elements = containerRef.current.querySelectorAll(
        `input[name="${name}"]`
      );
      if (isRange) {
        elements[0].value = getDateString(defaultDate[0]);
        elements[1].value = getDateString(defaultDate[1]);
      } else {
        elements[0].value = getDateString(defaultDate);
      }
      const updateElements = (dates) => {
        if (isRange) {
          elements[0].value = getDateString(dates[0]);
          elements[1].value = getDateString(dates[1]);
        } else {
          elements[0].value = getDateString(dates[0]);
        }
      };
      flatpickr(selectRef.current, {
        time_24hr: true,
        mode,
        enableTime,
        noCalendar,
        dateFormat,
        defaultDate: defaultDate,
        maxDate,
        minDate,
        onChange: function (selectedDates) {
          updateElements(selectedDates);
        },
        onClose: function (selectedDates) {
          updateElements(selectedDates);
        },
        locale: {
          rangeSeparator: " - ",
        },
      });
    }
  }, []);

  return (
    <div ref={containerRef} className={"relative"}>
      <input className="flatpickr flatpickr-input w-full" ref={selectRef} />
      <input
        className="flatpickr-hidden-input"
        name={name}
        required={required}
      />
      {isRange && (
        <input
          className="flatpickr-hidden-input"
          name={name}
          required={required}
        />
      )}
    </div>
  );
};

define("date-picker", create(DatePicker));
