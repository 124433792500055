import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { create, define } from "../web-component";
import useIsMounted from "ismounted";

const ConfirmDialog = ({ className, title, cancelText, confirmText }) => {
  const [activeItem, setActiveItem] = useState(null);

  const isMounted = useIsMounted();

  const handleClick = useCallback(
    (element) => (e) => {
      e.preventDefault();
      setActiveItem(element);
    },
    [setActiveItem]
  );

  useEffect(() => {
    const elements = document.querySelectorAll(
      `[data-confirm-required="${className}"]`
    );
    const removeEventListeners = [];
    if (isMounted.current) {
      elements.forEach((element) => {
        const onClick = handleClick(element);
        element.addEventListener("click", onClick);
        removeEventListeners.push(() => {
          element.removeEventListener("click", onClick);
        });
      });
    }
    return () => {
      removeEventListeners.forEach((remove) => remove());
    };
  }, [isMounted, setActiveItem]);

  const handleCancel = useCallback(() => {
    setActiveItem(null);
  }, [setActiveItem]);

  const handleConfirm = useCallback(() => {
    activeItem.form.submit();
  }, [activeItem]);

  if (!activeItem) {
    return null;
  }

  return (
    <div
      className={cx(
        "w-screen h-screen fixed inset-0 bg-opacity-75 bg-black z-20",
        "flex flex-col items-center justify-center"
      )}
    >
      <div className="px-8 py-4 flex flex-col bg-white rounded">
        <div className="text-center">
          <span className="text-lg text-black">{title}</span>
        </div>
        <div className="flex flex-row mt-4 justify-end">
          <button className="bio-button mr-4" onClick={handleCancel}>
            {cancelText}
          </button>
          <button className="bio-button ml-4" onClick={handleConfirm}>
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

define("confirm-dialog", create(ConfirmDialog));
