import React, { useCallback, useState } from "react";
import cx from "classnames";
import { create, define } from "../web-component";

const inputClass = "w-full sm:w-40 lg:w-48 p-2";

const getGridCols = ({ hideContent, items }) => {
  if (hideContent && items.length === 0) {
    return "md:grid-cols-2";
  }
  if (hideContent || items.length === 0) {
    return "md:grid-cols-3";
  }
  return "md:grid-cols-4";
};

const PostFilter = ({
  type,
  items,
  expertises,
  defaultType,
  defaultContent,
  defaultExpertise,
  searchTypeText,
  searchExpertiseText,
  searchText,
  contentText,
  hideContent,
  allText,
}) => {
  const [selectType, setSelectType] = useState(defaultType);
  const [inputContent, setInputContent] = useState(defaultContent);
  const [selectExpertise, setSelectExpertise] = useState(defaultExpertise);

  const handleSelect = useCallback(
    (e) => {
      setSelectType(e.target.value);
    },
    [setSelectType]
  );

  const handleChange = useCallback(
    (e) => {
      setInputContent(e.target.value);
    },
    [setInputContent]
  );

  const handleSelectExpertise = useCallback(
    (e) => {
      setSelectExpertise(e.target.value);
    },
    [setSelectExpertise]
  );

  const gridCols = getGridCols({ hideContent, items });

  return (
    <form action={`/${type}`} method="GET">
      <div
        className={`w-screen px-4 grid gap-4 grid-cols-1 sm:w-auto sm:px-0 sm:grid-cols-2 ${gridCols}`}
      >
        {items.length > 0 && (
          <select
            className={cx(inputClass)}
            name="type"
            value={selectType}
            onChange={handleSelect}
          >
            <option className="hidden" value={""}>
              {searchTypeText}
            </option>
            <option value={""}>{allText}</option>
            {items.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        )}
        <select
          className={cx(inputClass)}
          name="expertise"
          value={selectExpertise}
          onChange={handleSelectExpertise}
        >
          <option className="hidden" value={""}>
            {searchExpertiseText}
          </option>
          <option value={""}>{allText}</option>
          {expertises.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        {!hideContent && (
          <input
            type="text"
            name="content"
            value={inputContent}
            onChange={handleChange}
            className={cx(inputClass, "border")}
            placeholder={contentText}
          />
        )}
        <button type="submit" className="bg-gray-bold px-8 py-2 text-white">
          {searchText}
        </button>
      </div>
    </form>
  );
};

define("post-filter", create(PostFilter));
