import React, { useCallback, useEffect, useRef } from "react";
import Select from "react-select/creatable";
import { create, define } from "../web-component";

const revertValue = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    !value;
  }
};

const ExpertiseSelect = ({
  name,
  defaultValue,
  className,
  options,
  placeholder,
  required,
  createLabel,
}) => {
  const selectRef = useRef(null);
  const setInputRequired = useCallback(
    (value) => {
      // Creatable has nested select
      if (selectRef.current?.select?.select?.inputRef && required) {
        selectRef.current.select.select.inputRef.required = value;
      }
    },
    [selectRef]
  );

  useEffect(() => {
    setInputRequired(revertValue(defaultValue));
  }, [defaultValue]);

  const handleChange = useCallback(
    (value) => {
      setInputRequired(revertValue(value));
    },
    [selectRef, require]
  );

  return (
    <div className="w-full h-full">
      <Select
        ref={selectRef}
        name={name}
        className={className}
        styles={{
          control: (provided) => ({
            ...provided,
            backgroundColor: "var(--gray-thin)",
            borderColor: "var(--gray-light)",
          }),
        }}
        isMulti
        formatCreateLabel={(input) => `${createLabel} ${input}`}
        defaultValue={defaultValue}
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </div>
  );
};

define("expertise-select", create(ExpertiseSelect));
