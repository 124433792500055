import React, { useCallback, useEffect, useMemo, useState } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import cx from "classnames";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { create, define } from "../web-component";
import { useDebounce } from "../hooks";

const resizeImage = (image, maxSize) => {
  const canvas = document.createElement("canvas");
  let width = image.width;
  let height = image.height;
  if (width > height) {
    if (width > maxSize) {
      height *= maxSize / width;
      width = maxSize;
    }
  } else {
    if (height > maxSize) {
      width *= maxSize / height;
      height = maxSize;
    }
  }
  canvas.width = width;
  canvas.height = height;
  canvas.getContext("2d").drawImage(image, 0, 0, width, height);
  return canvas.toDataURL("image/jpeg");
};

function myBlockRenderer(contentBlock) {
  const type = contentBlock.getType();

  // Convert image type to mediaComponent
  if (type === "atomic") {
    return {
      component: MediaComponent,
      editable: false,
      props: {
        foo: "bar",
      },
    };
  }
}

class MediaComponent extends React.Component {
  render() {
    const { block, contentState } = this.props;
    // const { foo } = this.props.blockProps;
    let data = {};
    try {
      data = contentState.getEntity(block.getEntityAt(0)).getData();
    } catch (e) {
      console.log(data);
    }

    const emptyHtml = " ";
    return (
      <div>
        {emptyHtml}
        <img
          src={data.src}
          alt={data.alt || ""}
          style={{ height: data.height || "auto", width: data.width || "auto" }}
        />
      </div>
    );
  }
}

const CopyButton = ({ editorState }) => {
  const save = () => {
    window.APP_STATE.DRAFT_EDITOR_COPY_STATE = editorState;
    alert("已複製");
  };

  return (
    <div className="m-1 cursor-pointer" onClick={save}>
      <i className="material-icons sm">content_copy</i>
    </div>
  );
};

const PasteButton = ({ paste }) => {
  const save = () => {
    try {
      paste(window.APP_STATE.DRAFT_EDITOR_COPY_STATE);
    } catch (e) {
      alert("貼上內容失敗");
    }
  };

  return (
    <div className="m-1 cursor-pointer" onClick={save}>
      <i className="material-icons sm">content_paste</i>
    </div>
  );
};

const DraftEditor = ({
  element,
  defaultState,
  readOnly,
  maxImageSize = 1366,
}) => {
  const propState = useMemo(
    () =>
      defaultState
        ? EditorState.createWithContent(convertFromRaw(defaultState))
        : EditorState.createEmpty(),
    [defaultState]
  );
  const [state, setState] = useState(propState);

  const debouncedState = useDebounce(state, 300);

  const syncElementValue = useCallback(
    (state) => {
      const el = document.querySelector(element);
      if (el) {
        const content = state.getCurrentContent();
        if (content.hasText()) {
          el.value = JSON.stringify(convertToRaw(content));
        }
      }
    },
    [element]
  );

  useEffect(() => {
    syncElementValue(debouncedState);
  }, [debouncedState]);

  const handleStateChange = useCallback(
    (newState) => {
      try {
        setState(newState);
      } catch (e) {
        console.error(e);
      }
    },
    [setState]
  );

  return (
    <div className={cx({ "border bg-gray-thin": !readOnly })}>
      <Editor
        // FIXME: chinese input image exception https://github.com/jpuri/react-draft-wysiwyg/issues/979
        blockRendererFn={myBlockRenderer}
        editorState={state}
        readOnly={readOnly}
        toolbarHidden={readOnly}
        toolbar={{
          image: {
            uploadEnabled: true,
            uploadCallback: (file) =>
              new Promise((resolve) => {
                if (file) {
                  let reader = new FileReader();
                  reader.onload = (e) => {
                    const image = new Image();
                    image.onload = function () {
                      const form = new FormData();
                      form.append("file", resizeImage(image, maxImageSize));
                      fetch("/api/uploadBase64", {
                        method: "POST",
                        headers: {
                          "CSRF-Token": window.APP_STATE.csrfToken,
                        },
                        credentials: "include",
                        body: form,
                      })
                        .then((res) => res.json())
                        .then((result) => {
                          resolve({
                            data: { link: result.url },
                          });
                        });
                    };
                    image.src = e.target.result;
                  };
                  reader.readAsDataURL(file);
                }
              }),
            previewImage: true,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: "auto",
              width: "auto",
            },
          },
        }}
        toolbarCustomButtons={[
          <CopyButton key="copy" />,
          <PasteButton key="paste" paste={setState} />,
        ]}
        localization={{
          locale:
            window.APP_STATE?.locale?.toLowerCase().replace("-", "_") || "en",
        }}
        wrapperClassName={cx(
          { "min-h-.6-screen max-h-.8-screen": !readOnly },
          "flex flex-col"
        )}
        editorClassName="h-0 flex-1 p-2"
        onEditorStateChange={handleStateChange}
      />
    </div>
  );
};

export default DraftEditor;

define("draft-editor", create(DraftEditor));
