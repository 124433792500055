import React, { useCallback, useState } from "react";
import { create, define } from "../web-component";

const DegreeSelect = ({
  name,
  defaultValue,
  defaultLocaleValue,
  required,
  items,
  isStudent,
  labelText,
  labelTextStudent,
  otherText,
  locales,
  localeLabels,
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = useCallback((e) => setValue(e.target.value), [setValue]);

  if (!isStudent) {
    return (
      <div className="w-full flex flex-col my-2 md:flex-row">
        {locales.map((locale) => (
          <div
            key={locale}
            className="w-auto flex-1 flex flex-col px-2 my-2 md:w-0"
          >
            <label>
              {labelText}({localeLabels[locale]}){required ? "*" : ""}
            </label>
            <input
              className="border"
              name={`${name}_other[${locale}]`}
              type="text"
              defaultValue={
                defaultLocaleValue ? defaultLocaleValue[locale] : null
              }
              placeholder={localeLabels[locale]}
              required={required}
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col px-2">
      <label>
        {labelTextStudent}
        {required ? "*" : ""}
      </label>
      <div className="flex flex-wrap md:flex-col">
        <div className="flex flex-row md:flex-col">
          {items.map((item) => (
            <label key={item.value} className="mr-4">
              <input
                type="radio"
                className="mr-2"
                name={`_${name}`}
                value={item.value}
                onChange={handleChange}
                checked={value === item.value}
              />
              {item.label}
            </label>
          ))}
        </div>
        <label className="flex flex-row">
          <input
            type="radio"
            className="mr-2 mt-4"
            name={`_${name}`}
            value="other"
            checked={value === "other"}
            onChange={handleChange}
          />
          <div className="flex-1 flex flex-col sm:flex-row">
            {locales.map((locale) => (
              <input
                key={locale}
                type="text"
                defaultValue={
                  defaultLocaleValue ? defaultLocaleValue[locale] : null
                }
                className="border w-full m-2"
                name={`${name}_other[${locale}]`}
                disabled={items.some((item) => item.value === value)}
                placeholder={`${otherText}(${localeLabels[locale]})`}
              />
            ))}
          </div>
        </label>
      </div>
      <input
        className="hidden"
        name={name}
        value={value}
        readOnly
        required={required}
      />
    </div>
  );
};

define("degree-select", create(DegreeSelect));
