import React from "react";
import { Pie } from "@reactchartjs/react-chart.js";
import { create, define } from "../web-component";

const JobResumeMatchChart = ({ human, organization, score0 }) => {
  const data = {
    labels: [human.label, organization.label, score0.label],
    datasets: [
      {
        label: "% of Members type",
        data: [human.count, organization.count, score0.count],
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(256, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <Pie
      data={data}
      options={{ legend: { display: false } }}
      height={120}
      width={120}
    />
  );
};

define("job-resume-match-chart", create(JobResumeMatchChart));
