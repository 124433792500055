import React, { useRef, useCallback, useEffect } from "react";
import Select from "react-select";
import { create, define } from "../web-component";

const OrganizationSelect = ({ name, data, defaultValue, placeholder }) => {
  const selectRef = useRef(null);
  const setInputRequired = useCallback(
    (value) => {
      if (selectRef.current?.select?.inputRef) {
        selectRef.current.select.inputRef.required = value;
      }
    },
    [selectRef]
  );
  useEffect(() => {
    setInputRequired(!defaultValue);
  }, [defaultValue]);

  const handleChange = useCallback(
    (value) => {
      setInputRequired(!value);
    },
    [selectRef, require]
  );
  return (
    <Select
      ref={selectRef}
      name={name}
      defaultValue={defaultValue}
      options={data}
      placeholder={placeholder}
      onChange={handleChange}
    ></Select>
  );
};

export default OrganizationSelect;

define("organization-select", create(OrganizationSelect));
