import React from "react";
import cx from "classnames";
import { createUseStyles } from "react-jss";
import IconMenu from "./IconMenu";
import { create, define } from "../web-component";

const useStyles = createUseStyles({
  item: {
    color: "var(--gray-light)",
  },
  active: {
    color: "black",
  },
});

const LocaleMenu = ({ label, items }) => {
  const classes = useStyles();
  return (
    <IconMenu icon="language" label={label}>
      {items.map(({ href, text, active }) => (
        <a
          key={text}
          className={cx(classes.item, { [classes.active]: active })}
          href={href}
        >
          <div className="whitespace-no-wrap mx-4">{text}</div>
        </a>
      ))}
    </IconMenu>
  );
};

define("locale-menu", create(LocaleMenu));
