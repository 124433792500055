import React, { useState } from "react";
import { create, define } from "../web-component";

const RichText = ({ text, start, children }) => {
  const [count, setCount] = useState(start);
  return (
    <button
      onClick={() => {
        setCount(count + 1);
      }}
    >
      {text}: {count} | {children} | <slot name="children"></slot>
    </button>
  );
};

define("rich-text", create(RichText, { shadow: true }));
