import React from "react";
import ReactDOM from "react-dom";
import htmlToReact from "html-to-react";
import { create as createJss } from "jss";
import { JssProvider } from "react-jss";

export const define = (tagName, ComponentConstructor) =>
  customElements.define(tagName, ComponentConstructor);

export const create = (ReactComponent, option = {}) =>
  class WebComponent extends HTMLElement {
    constructor() {
      super();
      if (option.shadow) {
        this.root = this.attachShadow({ mode: "open" });
      } else {
        this.root = this;
      }
      this.observer = new MutationObserver(() => this.update());
      this.observer.observe(this, { attributes: true });
    }

    connectedCallback() {
      this._innerHTML = this.root.innerHTML;
      this.mount();
    }

    disconnectedCallback() {
      this.unmount();
      this.observer.disconnect();
    }

    update() {
      this.unmount();
      this.mount();
    }

    mount() {
      const props = {
        ...this.getProps(this.attributes, ReactComponent.propTypes),
        ...this.getEvents(ReactComponent.propTypes),
        children: this.parseHtmlToReact(this._innerHTML),
      };
      let Component = ReactComponent;
      if (option.useJss && option.shadow) {
        const mountPoint = document.createElement("div");
        this.root = this.root.appendChild(mountPoint);
        const jss = createJss({
          insertionPoint: this.root,
        });
        // eslint-disable-next-line react/display-name
        Component = (props) => (
          <JssProvider jss={jss}>
            <ReactComponent {...props} />
          </JssProvider>
        );
      }
      ReactDOM.render(<Component {...props} />, this.root);
    }

    unmount() {
      ReactDOM.unmountComponentAtNode(this.root);
    }

    getEvents(propTypes) {
      return Object.keys(propTypes || {})
        .filter((key) => /on([A-Z].*)/.exec(key))
        .reduce(
          (events, ev) => ({
            ...events,
            [ev]: (args) =>
              this.dispatchEvent(new CustomEvent(ev, { ...args })),
          }),
          {}
        );
    }

    getProps(attributes, propTypes) {
      propTypes = propTypes || {};
      return [...attributes]
        .filter((attr) => attr.name !== "style")
        .map((attr) => this.convert(propTypes, attr.name, attr.value))
        .reduce((props, prop) => ({ ...props, [prop.name]: prop.value }), {});
    }

    convert(propTypes, attrName, attrValue) {
      let propName =
        Object.keys(propTypes).find((key) => key.toLowerCase() == attrName) ||
        attrName;
      // support kebab case property name
      if (/(.*-.*)/.exec(attrName)) propName = this.camelize(propName);
      let value = attrValue;
      try {
        if (attrValue === "true" || attrValue === "false")
          value = attrValue == "true";
        else if (!isNaN(attrValue) && attrValue !== "") value = +attrValue;
        else if (/^{.*}/.exec(attrValue)) value = JSON.parse(attrValue);
        // support array
        else if (/^\[.*\]/.exec(attrValue)) value = JSON.parse(attrValue);
        // support html
        else if (/^<.*>/.exec(attrValue))
          value = this.parseHtmlToReact(attrValue);
      } catch (e) {
        console.log(e);
        value = "";
      }
      return {
        name: propName,
        value: value,
      };
    }

    camelize(s) {
      return s.replace(/-./g, (x) => x.toUpperCase()[1]);
    }

    parseHtmlToReact(html) {
      return html && new htmlToReact.Parser().parse(html);
    }
  };

export const create2 = (ReactComponent) =>
  class WebComponent extends HTMLElement {
    mountPoint = null;
    componentAttributes = {};
    componentProperties = {};

    connectedCallback() {
      this.mountReactApp();
    }

    attributeChangedCallback(name, oldVal, newVal) {
      this.componentAttributes[name] = newVal;

      this.mountReactApp();
    }

    reactProps() {
      return { ...this.componentAttributes, ...this.componentProperties };
    }

    disconnectedCallback() {
      ReactDOM.unmountComponentAtNode(this.mountPoint);
    }

    mountReactApp() {
      if (!this.mountPoint) {
        const shadow = this.attachShadow({ mode: "open" });
        this.mountPoint = document.createElement("div");
        shadow.appendChild(this.mountPoint);

        // make sure events passed to React component
        Object.defineProperty(this.mountPoint, "ownerDocument", {
          value: shadow,
        });
        shadow.createElement = (...args) => document.createElement(...args);
        shadow.createElementNS = (...args) => document.createElementNS(...args);
        shadow.createTextNode = (...args) => document.createTextNode(...args);
      }

      const attrs = [...this.attributes].reduce(
        (a, b) => ({ ...a, [b.name]: b.value }),
        {}
      );
      ReactDOM.render(
        <ReactComponent {...attrs} {...this.reactProps()} />,
        this.mountPoint
      );
    }
  };
