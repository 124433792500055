import React, { useEffect, useState } from "react";
import cx from "classnames";
import { createUseStyles } from "react-jss";
import { create, define } from "../web-component";

const useStyles = createUseStyles({
  menu: {
    transform: ({ open }) => `scale(${open ? 1 : 0})`,
    transformOrigin: "center right",
    transition: `transform 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s`,
  },
});

const IconMenu = ({ icon, label, children, onOpen }) => {
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles({ open });

  useEffect(() => {
    if (onOpen) {
      onOpen(open);
    }
  }, [onOpen, open]);

  return (
    <>
      {open && (
        <div
          className="fixed inset-0 z-10"
          onClick={() => {
            setOpen(false);
          }}
        ></div>
      )}
      <div
        className="relative"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => {
          // setOpen(false);
          setHover(false);
        }}
        onClick={() => setOpen(!open)}
      >
        <div
          className={cx(
            "px-3 py-2",
            "flex flex-col items-center justify-center",
            "hidden sm:flex"
          )}
        >
          {hover ? (
            <span className="cursor-pointer">{label}</span>
          ) : (
            <i className="material-icons cursor-pointer">{icon}</i>
          )}
        </div>
        <div
          className={cx(
            classes.menu,
            "absolute top-2 right-4 bg-white rounded-full shadow-md px-4 py-2 border z-10",
            "flex flex-row",
            "hidden sm:flex"
          )}
        >
          {children}
        </div>
        <div className={cx("flex flex-row justify-center", "sm:hidden")}>
          {children}
        </div>
      </div>
    </>
  );
};

define("icon-menu", create(IconMenu));

export default IconMenu;
