import React, { useCallback, useState } from "react";
import cx from "classnames";
import { create, define } from "../web-component";
import DraftEditor from "./DraftEditor";

const ExpandableItem = ({
  type,
  title,
  subtitle,
  labels,
  content,
  contentType,
  navigateTo,
  navigateText,
  expandable = true,
  likes,
  liked,
  likable,
  csrfToken,
  id,
  premium,
  tracking,
  trackingType,
  updatedAt = null,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = useCallback(() => {
    if (expandable) {
      setExpanded(!expanded);
    } else {
      window.open(navigateTo, "_blank");
    }
  }, [expanded, setExpanded]);

  return (
    <div className="w-full h-full">
      <div
        className={cx(
          "w-full flex flex-row items-start px-4 py-4 border-b border-gray-bold cursor-pointer relative",
          {
            "bg-primary-ocean text-white shadow": expanded,
          }
        )}
        data-tracking-type={trackingType}
        data-title={title}
        data-tracking={tracking}
        onClick={handleClick}
      >
        {premium && (
          <div className="w-8 h-8">
            <img
              className="w-full h-full"
              src="/images/icons/premium.svg"
              alt="premium"
            />
          </div>
        )}
        <div className="flex-1 w-0 truncate flex flex-col items-start">
          {Array.isArray(labels) && (
            <div className={"flex flex-row mb-2"}>
              {labels.map((label) => (
                <div
                  key={label}
                  className={"text-xs border rounded-full px-2 py-1 mr-1"}
                >
                  {label}
                </div>
              ))}
            </div>
          )}
          <div className="flex flex-row items-center">
            {type && (
              <div className="border px-2 py-1 mr-1 text-xs">{type}</div>
            )}
            <span className="whitespace-no-wrap">{title}</span>
          </div>
          {subtitle && (
            <span className="text-gray-regular text-sm mt-2">{subtitle}</span>
          )}
        </div>
        <div className={cx({ "mb-4": updatedAt })}>
          {likes !== null && likes !== undefined && (
            <span
              className={cx("flex flex-col items-center", {
                "text-gray-bold": !expanded,
                "text-gray-light": expanded,
              })}
            >
              <div className="flex flex-row">
                {likable ? (
                  <form action="/resource/like" method="POST">
                    <input type="hidden" name="_csrf" value={csrfToken} />
                    <input type="hidden" name="id" value={id} />
                    {liked ? (
                      <>
                        <input type="hidden" name="unlike" value="true" />
                        <button
                          className="flex itesm-center justify-center"
                          data-reversed={expanded}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <i className="material-icons">favorite</i>
                        </button>
                      </>
                    ) : (
                      <button
                        className="flex itesm-center justify-center"
                        data-reversed={expanded}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <i className="material-icons">favorite_border</i>
                      </button>
                    )}
                  </form>
                ) : (
                  <i className="material-icons">favorite</i>
                )}
                <div className="ml-2">
                  <span className="text-sm">{likes}</span>
                </div>
              </div>
            </span>
          )}
          {updatedAt && (
            <div className={"text-sm absolute bottom-0 right-0 mr-4 mb-2"}>
              {updatedAt}
            </div>
          )}
        </div>
        {/* {!expanded && (
          <i className="material-icons">
            {expandable ? "add" : "keyboard_arrow_right"}
          </i>
        )} */}
      </div>
      {expanded ? (
        <div className="px-4 py-3 md:px-10 md:py-6 border bg-white">
          {contentType === "draft-editor" ? (
            <DraftEditor defaultState={content} readOnly />
          ) : (
            content
          )}
          {navigateTo && (
            <div className="flex justify-end">
              <a
                href={navigateTo}
                className="p-2"
                rel="noreferrer"
                target="_blank"
              >
                {navigateText}
              </a>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

define("expandable-item", create(ExpandableItem));
